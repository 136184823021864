// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from "./pages/Home";
import Services from "./pages/Services";
import Infivent from "./pages/Infivent";
import CaseStudies from "./pages/CaseStudies";
// import About from './pages/About';
// import Header from "./components/header/Header";
import Footer from "./components/Footer"
import Contact from './pages/Contact';
import Preloader from './components/preloader/Preloader';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CoockiePolicy from './pages/CoockiePolicy';
import NewHome from './pages/NewHome';
import NewAbout from './pages/NewAbout';
// import Industries from './pages/Industries';


function App() {
  // state variable for preloader function
  const [isLoading, setIsLoading] = useState(true);

  // Loader Create Fake Sync data
  useEffect(() => {
    const loaderFetch = () => {
      setTimeout(()=>{
        setIsLoading(false);
      }, 4000);
    }
    loaderFetch();
  },[]);
 
  return isLoading ? ( <Preloader />) : (
    <BrowserRouter>
       <Routes>
        <Route path="/" element={ <NewHome />} />
        {/* <Route path="/" element={ <Home />} /> */}
        <Route path="/services" element={ <Services />} />
        <Route path="/infivent" element={ <Infivent />} />
        <Route path="/case-studies" element={ <CaseStudies /> } />
        <Route path="/about" element={ <NewAbout /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
        <Route path="/cookie-policy" element={ <CoockiePolicy /> } />
        {/* <Route path="/industries" element={ <Industries/> } /> */}
        {/* <Route path="/aboutnew" element={ <NewAbout /> } /> */}
       </Routes>
      <Footer /> 
    </BrowserRouter>
  );
}

export default App;
